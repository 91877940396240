<template>
    <div> 

        <IconTopTable />
        <div class="content-table-results mt-2">
            <table class="table table-fixed table-stripes" id="table_Atu">
                <ColumnColGroup page="result" :column="data.column" :default_column="data.default_column" />
                <ColumnThead page="result" :column="data.column" :default_column="data.default_column" />
                <tbody id="tbody_Atu"> 
                    <template v-if="data.data.data && data.data.data.length > 0">
                        <template v-for="(item, key) in data.data.data">
                            <tr v-for="(sub_item, sub_key) in max_row(item.atu_france)" :key="key+'_'+sub_key" :class="odd_even_border(key, sub_key)">
                                <td class="middle">
                                    <template v-if="sub_key===0">
                                        <label class="table-check">
                                            <input type="checkbox" v-model="select_export" :value="item.drug_id">
                                            <span class="checkbox-table"></span>
                                        </label>
                                    </template>
                                </td>
                                <td>
                                    <template v-if="sub_key===0">
                                    <router-link :to="'/detail/'+item.drug_id+'/atu'" target="_blank" rel="noopener">
                                        <div class="icon-fiche"></div>
                                    </router-link>
                                    <div v-if="isAdmin()">
                                        <a :href="modifyPage('atu_france', item.drug_id)" target="_blank" rel="noopener"> 
                                            <em class="icon-edit"></em>
                                        </a>
                                    </div>
                                    </template>
                                </td>
                                <template v-for="(column) in data.column" :key="column.code" >

                                    <td v-if="column.Status && column.code == 'atu_023'" :code="column.code">
                                        <span v-html="ema_id(item.links)"></span>
                                    </td>
                                    <td v-if="column.Status && column.code == 'atu_001'" :code="column.code">
                                        <template v-if="sub_key === 0">{{item.drug_id}}</template>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'atu_003'" :code="column.code">
                                        <template v-if="sub_key === 0">{{ check_empty(item.category) }}</template>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'atu_004'" :code="column.code">
                                        <template v-if="sub_key === 0">{{check_empty(item.dci_new)}}</template>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'atu_005'" :code="column.code">
                                        <template  v-if="sub_key === 0">{{check_empty(item.name)}}</template>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'atu_006'" :code="column.code">                                
                                        <template  v-if="sub_key === 0">{{check_empty(item.firm)}}</template>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'atu_007'" :code="column.code">
                                        <span v-if="sub_key === 0" v-html="check_empty(array(item.atc))"></span>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'atu_008'" :code="column.code">
                                        <span v-if="sub_key === 0" v-html="check_empty(array(item['indexation_'+$i18n.locale]))"></span>
                                    </td> 
                                    <td v-else-if="column.Status && column.code == 'atu_009'" :code="column.code">
                                        <template v-if="sub_key === 0">{{ item.atu_france.motif ? $t('atu_motif.'+item.atu_france.motif) : "-" }}</template>
                                    </td>                                   
                                    <td v-else-if="column.Status && column.code == 'atu_010'" :code="column.code">
                                        <template v-if="sub_key === 0">{{ item.atu_france.avis ? $t(item.atu_france.avis) : "-" }}</template>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'atu_011'" :code="column.code">
                                        <template v-if="sub_key === 0">{{check_empty(DDMMMYY(item.atu_france.date_start))}}</template>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'atu_012'" :code="column.code">
                                        <template v-if="sub_key === 0">{{check_empty(DDMMMYY(item.atu_france.date_atu_start))}}</template>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'atu_013'" :code="column.code">
                                        <template v-if="sub_key === 0">{{check_empty(DDMMMYY(item.atu_france.date_end))}}</template>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'atu_014'" :code="column.code">
                                        <template v-if="sub_key === 0">                                            
                                            <Readmore :longText="$i18n.locale === 'fr' ? item.atu_france.indication_atu_fr : item.atu_france.indication_atu_en"></Readmore>                                            
                                        </template>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'atu_022'" :code="column.code">
                                        <span v-if="$i18n.locale === 'fr'" v-html="check_empty(array(item.classification_fr))"></span>
                                        <span v-else v-html="check_empty(array(item.classification_en))"></span>
                                    </td>

                                    <td v-else-if="column.Status && column.code == 'atu_030'" :code="column.code">
                                        <template v-if="sub_key === 0">{{check_empty(DDMMMYY(item.ma_date))}}</template>
                                    </td>
                                   
                                    <td v-if="column.Status && column.code == 'atu_024'" :code="column.code">
                                        <span v-html="trs_id(item.links)"></span>
                                    </td>
                                    <td v-if="column.Status && column.code == 'atu_025'" :code="column.code">
                                        {{check_empty(item.atu_france.tc_has_id)}}
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'atu_026'" :code="column.code">
                                        <template v-if="sub_key === 0">{{check_empty(DDMMMYY(item.atu_france.date_depot_ct))}}</template>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'atu_027'" :code="column.code">
                                        <template v-if="sub_key === 0">{{check_empty(DDMMMYY(item.atu_france.date_avis_ct))}}</template>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'atu_028'" :code="column.code">
                                        <template v-if="sub_key === 0">{{check_empty(DDMMMYY(item.rembursement_process_date))}}</template>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'atu_021'" :code="column.code">                                
                                        {{ check_empty_with_locale(item.atu_france.type_aap) }}
                                    </td>                    
                                    <template v-else-if="column.Status && column.code == 'atu_018'" :code="column.code">
                                        <td :code="'atu_0181'">
                                            <span v-if="sub_key === 0" v-html="hta_process_status_2(item)"></span>
                                        </td>
                                        <td :code="'atu_0182'">
                                            <span v-if="sub_key === 0" v-html="rembursement_status_2(item)"></span>
                                        </td>
                                    </template>

                                    <template v-else-if="column.Status && column.code == 'atu_029'" :code="column.code">
                                        <td :code="'atu_0291'">
                                            <span v-if="item.link_transparence_drug_center && item.link_transparence_drug_center[sub_key]" v-html="hta_process_status_2(item.link_transparence_drug_center[sub_key])"></span>
                                            <span v-else>-</span>
                                        </td>
                                        <td :code="'atu_0292'">
                                            <span v-if="item.link_transparence_drug_center && item.link_transparence_drug_center[sub_key]" v-html="rembursement_status_2(item.link_transparence_drug_center[sub_key])"></span>
                                            <span v-else>-</span>
                                        </td>
                                    </template>
                                    
                                    
                                </template>

                            </tr>
                        </template>

                    </template>

                    <template v-else><tr><td colspan="99">{{$t('No Data')}}</td></tr></template>

                </tbody>

            </table>
        </div>    
    </div>
</template>

<script>

import { computed } from 'vue'
import { useStore } from 'vuex'
import IconTopTable from '@/components/result/icon_top_table.vue';
import ColumnColGroup from '@/components/elements/column_colgroup.vue';
import ColumnThead from '@/components/elements/column_thead.vue';
import Readmore from '@/components/elements/readmore.vue';

import { isAdmin, hta_process_status_2, rembursement_status_2, modifyPage, icon_link, DDMMMYY, check_empty, array, odd_even_border, ema_id, trs_id, check_empty_with_locale } from '@/utils'
export default {
    name: "AtuFrance",
    components: {
        IconTopTable,
        ColumnColGroup,
        ColumnThead,
        Readmore,
    },
    setup(){
        const store = useStore()
        /**
         * Get data result search
        */
        const data = computed(() => store.getters['result/data'] );
        
        const select_export = computed({
            get () {
                if(store.getters['result/select_export_checkAll']){
                    return true
                }else{
                    return store.getters['result/select_export'] ? store.getters['result/select_export'].listID : null 
                }
            },
            set (value) { store.dispatch('result/select_export', value) }
        }); 

        const max_row = (param) => {
            let temp = []
            temp.push(1)
            if(param.post_atu){
                temp.push(param.post_atu.length)
            }
            if(param.link_transparence_drug_center){
                temp.push(param.link_transparence_drug_center.length)
            }
            return Math.max(...temp)
        }

        return {data, select_export, max_row, isAdmin, icon_link, DDMMMYY, check_empty, ema_id, trs_id,
        hta_process_status_2, rembursement_status_2, array, modifyPage, odd_even_border, check_empty_with_locale}
    },
}
</script>

<style scoped>
/* CSS for fixed height of table for show in one page */
@media only screen and (max-width: 862px){
    .content-table-results{
        height: auto;
    }
}
@media only screen and (min-width: 863px){
    .content-table-results{
        max-height: 70vh;
    }
}
@media only screen and (min-height: 863px){
    .content-table-results{
        max-height: 65vh;
    }
}
@media only screen and (min-height: 1320px){
    .content-table-results{
        max-height: 75vh;
    }
}
</style>
 